export { PhoneAreaCodesData } from './phoneAreaCodes'
export { PhoneTypesData } from './phoneTypes'
export { RadioAddressData } from './radio-address'
export const DEFAULT_ADDRESS = {
  main: '',
  additional: '',
  zipCode: '',
  city: '',
  area: '',
  country: {
    id: 75
  }
}
