<script setup>
import { watch, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAuthentificationStore } from '@/store/authentification'
import { useProjectsStore } from '@/store/projects'
import { useCustomersStore } from '@/store/customers'
import { useContactsStore } from '@/store/contacts'
import { useSettingsStore } from '@/store/settings'
import * as PublicRoutes from './datas/public/routes'
import { ROUTE_BASE as ROUTE_PLANNING } from './datas/planning/routes'
import { ROUTE_BASE as ROUTE_CUSTOMERS } from './datas/customers/routes'
import { ROUTE_BASE as ROUTE_PROJECTS } from './datas/projects/routes'
import { ROUTE_BASE as ROUTE_COMMUNITY } from './datas/community/routes'
import { ROUTE_BASE as ROUTE_PROVIDERS } from './datas/providers/routes'
import { ROUTE_BASE as ROUTE_DOCUMENTS } from './datas/documents/routes'
import { ROUTE_BASE as ROUTE_COMMUNICATION } from './datas/communication/routes'
import { ROUTE_BASE as ROUTE_ACCOUNTING } from './datas/accounting/routes'
import { ROUTE_BASE as ROUTE_STATS } from './datas/stats/routes'
import { ROUTE_BASE as ROUTE_SETTINGS, ROUTE_MY_PROFILE } from './datas/settings/routes'
import { ROUTE_BASE as ROUTE_SUPPORT } from './datas/support/routes'
import { ROUTE_BASE as ROUTE_HELP } from './datas/help/routes'
import NbsIcon from '@/components/NbsIcon.vue'
import NbsLogo from '@/components/NbsLogo.vue'
import NbsCard from '@/components/cards/NbsCard.vue'
import NbsMenu from '@/components/NbsMenu.vue'
import NbsUserIcon from '@/components/svg/NbsUserIcon.vue'

const authentificationStore = useAuthentificationStore()
const projectsStore = useProjectsStore()
const customersStore = useCustomersStore()
const contactsStore = useContactsStore()
const settingsStore = useSettingsStore()
const router = useRouter()
const route = useRoute()
const isLoading = ref(false)

router.beforeEach(async (to) => {
  isLoading.value = true
  if (!authentificationStore.isLogged && !to.path.includes('login')) {
    return { path: PublicRoutes.ROUTE_LOGIN_BASE, query: { from: to.fullPath } }
  } else if (authentificationStore.isLogged && to.path.includes('login')) {
    return { path: '/' }
  } else {
    if (authentificationStore.isLogged) {
      customersStore.resetChecked()
      projectsStore.resetChecked()
      contactsStore.resetChecked()
      if (customersStore.getListNumber === 0) {
        await customersStore.fetch()
      }
      if (projectsStore.getListNumber === 0) {
        await projectsStore.fetch()
      }
      if (!authentificationStore.loggedUser.login) {
        await authentificationStore.fetch()
      }
      if (contactsStore.getListNumber === 0) {
        await contactsStore.fetch()
      }
      if (!settingsStore.data.reference) {
        await settingsStore.fetch()
      }
      if (settingsStore.data._links && settingsStore.data._links.logo && !settingsStore.files.logo) {
        await settingsStore.fetchLogo()
      }
    }
  }
})

router.afterEach(() => {
  isLoading.value = false
})

watch(() => authentificationStore.token, (newValue) => {
  if (newValue === '') {
    router.push({ path: PublicRoutes.ROUTE_LOGIN_BASE, query: { from: route.fullPath } })
  }
})

watch(() => customersStore.getListNumber, (newValue) => {
  NAV_ADMIN.value[2].itemsLength = newValue
})

watch(() => projectsStore.getListNumber, (newValue) => {
  NAV_ADMIN.value[3].itemsLength = newValue
})

watch(() => contactsStore.getListNumber, (newValue) => {
  NAV_ADMIN.value[6].itemsLength = newValue
})

const NAV_ADMIN = ref([
  {
    icon: {
      name: 'dashboard'
    },
    label: 'Tableau de bord',
    url: '/'
  },
  {
    icon: {
      name: 'calendar-checked'
    },
    label: 'Planning',
    url: ROUTE_PLANNING
  },
  {
    icon: {
      name: 'customers'
    },
    label: 'Clients',
    url: ROUTE_CUSTOMERS,
    itemsLength: customersStore.getListNumber,
    notifAlert: true
  },
  {
    icon: {
      name: 'folder'
    },
    label: 'Projets',
    url: ROUTE_PROJECTS,
    itemsLength: projectsStore.getListNumber
  },
  {
    icon: {
      name: 'providers'
    },
    label: 'Prestataire tiers',
    url: ROUTE_PROVIDERS,
    itemsLength: 0
  },
  {
    icon: {
      name: 'documents'
    },
    label: 'Documents',
    url: ROUTE_DOCUMENTS,
    itemsLength: 200
  },
  {
    icon: {
      name: 'community'
    },
    label: 'Communauté',
    url: ROUTE_COMMUNITY,
    itemsLength: contactsStore.getListNumber,
    isGroup: true
  },
  {
    icon: {
      name: 'msg'
    },
    label: 'Communication',
    url: ROUTE_COMMUNICATION
  },
  {
    icon: {
      name: 'catalog'
    },
    label: 'Catalogue produits',
    url: '/products',
    isGroup: true
  },
  {
    icon: {
      name: 'accounting'
    },
    label: 'Comptabilité',
    url: ROUTE_ACCOUNTING
  },
  {
    icon: {
      name: 'stats'
    },
    label: 'Statistique',
    url: ROUTE_STATS
  },
  {
    icon: {
      name: 'settings'
    },
    label: 'Paramètres',
    url: ROUTE_SETTINGS,
    isGroup: true
  },
  {
    icon: {
      name: 'support'
    },
    label: 'Support',
    url: ROUTE_SUPPORT
  },
  {
    icon: {
      name: 'help'
    },
    label: 'Aides et informations',
    url: ROUTE_HELP
  }
])

const NAV_CONTACT = ref([
  {
    icon: {
      name: 'dashboard'
    },
    label: 'Tableau de bord',
    url: '/'
  }
])
</script>

<template>
  <div class="nbs-main" :class="{ 'nbs-main--login' : !authentificationStore.isLogged }">
    <header class="nbs-header" v-if="authentificationStore.isLogged">
      <nbs-icon icon="bs" class="nbs-header-logo"/>
      <router-link type="button" class="nbs-header-btn" :to="ROUTE_SETTINGS + '/' + ROUTE_MY_PROFILE">
        <img
          v-if="authentificationStore.files.avatar"
          class="nbs-header-avatar" alt=""
          :src="authentificationStore.files.avatar.fullpath">
        <nbs-user-icon class="nbs-header-avatar" v-else></nbs-user-icon>
      </router-link>
    </header>
    <div class="nbs-nav" v-if="authentificationStore.isLogged">
      <nbs-card class="nbs-nav-card" :route="ROUTE_SETTINGS">
        <div class="nbs-card-header nbs-nav-cardHeader">
          <nbs-logo :url="settingsStore.files.logo ? settingsStore.files.logo.fullpath : ''"></nbs-logo>
        </div>
        <div class="nbs-card-content nbs-nav-cardContent">
          <div role="heading" aria-level="1" class="nbs-card-title nbs-nav-cardTitle">{{ settingsStore.data.companyName }}</div>
          {{ settingsStore.data.reference }}
        </div>
      </nbs-card>
      <nbs-menu :items="authentificationStore.isAdmin ? NAV_ADMIN : NAV_CONTACT"></nbs-menu>
    </div>
    <div :class="{ 'nbs-main-login' : !authentificationStore.isLogged }">
      <router-view :key="$route.fullPath" v-if="!isLoading" />
    </div>
  </div>
</template>

<style lang="scss">
@use 'sass-mq' as *;
@import './assets/styles/_mixins.scss';
@import './assets/styles/_variables.scss';

.nbs-main {
    display: grid;
    grid-gap: 0;
    grid-template-columns: rem(64px) rem(132px) auto;
    grid-auto-rows: minmax(rem(100px), auto);
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $black-88;

    @include mq($from: wide) {
      grid-template-columns: rem(64px) rem(300px) auto;
    }

    &--login {
      display: block;
    }

    &-login {
      @include mq($from: desktop) {
        display: grid;
        grid-gap: 0;
        grid-template-columns: 64% auto;
        height: 100%;
      }
    }
}

.nbs-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: rem(20px) 0;
  background: $black-88;

  &-logo {
    width: rem(24px);
    height: rem(24px);
    color: $white;
  }

  &-avatar {
    max-width: 100%;
    border-radius: 50%;
  }

  &-btn {
    border: 0;
    border-radius: 50%;
    padding: rem(2px);
    width: rem(28px);
    height: rem(28px);
    background: $white;
  }
}

.nbs-nav {
  border-right: rem(1px) solid $black-08;
  padding: rem(20px) $gutters;
  overflow-y: auto;
  overflow-x: hidden;

  &-card {
    display: flex;
    align-items: center;
    color: $black-64;

    &Header {
      @include mq($from: wide) {
        width: rem(32px);
        height: rem(32px);
        margin-right: $gutters;
        border-radius: 50%;
        overflow: hidden;
        background-color: $black-04;
      }
    }

    &Content {
      display: none;

      @include mq($from: wide) {
        display: block;
      }
    }

    &Title {
      text-transform: uppercase;
      color: $black-88;
    }
  }

  &-menu {
    padding: rem(20px) 0;
  }
}
</style>
