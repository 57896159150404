import { ref, computed } from 'vue'
import moment from 'moment'
import useAPI from '@/composables/useApi'
import useUploadFile from '@/composables/useUploadFile'

moment.locale('fr')

export const useFile = () => {
  const API = useAPI()
  const file = ref({})

  const formatFileData = (data) => {
    if (data.createdAt) {
      data.formattedCreatedAt = moment(data.createdAt).format('LL')
    }
    if (data.name) {
      data.fullpath = process.env.VUE_APP_MEDIAS_URL + '/' + data.domain + data.name + '.' + data.extension
    }
    return data
  }

  const fetchCreator = async () => {
    if (file.value._links && file.value._links.creator) {
      const response = await API.fetch(file.value._links.creator.href, {
        method: 'get'
      })
      file.value.creator = response.data
      return response
    }
  }

  const fetchEditor = async () => {
    if (file.value._links && file.value._links.editor) {
      const response = await API.fetch(file.value._links.editor.href, {
        method: 'get'
      })
      file.value.editor = response.data
      return response
    }
  }

  const fetch = async (url, usersDataFetching = false) => {
    const response = await API.fetch(url, {
      method: 'get'
    })
    set(response.data)
    if (usersDataFetching) {
      await fetchCreator()
      await fetchEditor()
    }
    return formatFileData(file.value)
  }

  const upload = async ({ filePath, type, domain, id }) => {
    const uf = useUploadFile()
    let fmData = new FormData()
    fmData = {
      file: filePath,
      domain,
      type,
      id
    }
    const response = await uf.upload(fmData)
    const data = formatFileData(response.data)
    file.value = data
    return data
  }

  const remove = async () => {
    const response = await API.fetch('/file/' + file.value.id, {
      method: 'delete'
    })
    file.value = {}
    return response
  }

  const approve = async () => {
    const response = await API.fetch('/file/approve/' + file.value.id, {
      method: 'get'
    })
    file.value.state = 1
    return response
  }

  const refuse = async () => {
    const response = await API.fetch('/file/refuse/' + file.value.id, {
      method: 'get'
    })
    file.value.state = 2
    return response
  }

  const changeSignMode = async (mode) => {
    const response = await API.fetch('/api/v1/file/change/mode/' + file.value.id + '/' + mode, {
      method: 'post'
    })
    file.value.signMode = mode
    return response
  }

  const set = (data) => {
    file.value = formatFileData(data)
  }

  const get = computed(() => file.value)

  const reset = () => {
    set({})
  }

  return {
    fetch,
    upload,
    remove,
    approve,
    refuse,
    changeSignMode,
    set,
    get,
    reset
  }
}

export default useFile
