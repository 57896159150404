import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useStorage } from '@vueuse/core'
import { useAPI, useFile, useComputedUserData } from '@/composables'

const STORAGE_KEY = 'NAZ_TOKEN'

export const useAuthentificationStore = defineStore('useAuthentificationStore', () => {
  const token = useStorage(STORAGE_KEY, '')
  const loggedUser = ref({
    address: null
  })
  const API = useAPI()
  const LogoFile = useFile()
  const files = ref({
    avatar: null
  })
  const computedUserData = useComputedUserData()

  const isLogged = computed(() => {
    return token.value !== ''
  })

  const login = async (username, password) => {
    const response = await API.fetch('/login', {
      method: 'POST',
      data: {
        username,
        password
      },
      version: ''
    })
    if (response.data.token) {
      setToken(response.data.token)
      fetch()
    }
    return response
  }

  const fetchCountry = async (link) => {
    if (link) {
      const response = await API.fetch(link, { method: 'get' })
      loggedUser.value.address.country = response.data
      computedUserData.setData(loggedUser.value)
      return response
    }
  }

  const fetchAddress = async () => {
    if (loggedUser.value._links && loggedUser.value._links.address) {
      const response = await API.fetch(loggedUser.value._links.address.href, { method: 'get' })
      if (response.data) {
        loggedUser.value.address = response.data
        computedUserData.setData(loggedUser.value)
        try {
          if (response.data._links.country) {
            await fetchCountry(response.data._links.country.href)
          }
        } catch (e) {
          console.error('fetchCountry', e)
        }
      }
    }
  }

  const setAvatar = (value) => {
    files.value.avatar = value
    computedUserData.setFiles(files.value)
  }

  const fetchAvatar = async () => {
    if (loggedUser.value._links && loggedUser.value._links.avatar && loggedUser.value._links.avatar.href) {
      setAvatar(await LogoFile.fetch(loggedUser.value._links.avatar.href))
    }
  }

  const fetch = async () => {
    const response = await API.fetch('/me', { method: 'get' })
    loggedUser.value = response.data
    Object.assign(loggedUser.value, {
      rank: computedUserData.isAdmin ? 'SUPER' : 'CONTACT'
    })
    computedUserData.setData(loggedUser.value)
    fetchAvatar()
    fetchAddress()
    return response
  }

  const setToken = (value) => {
    token.value = value
    localStorage.setItem(STORAGE_KEY, token)
  }

  const disconnect = () => {
    token.value = ''
    loggedUser.value = {}
    localStorage.removeItem(STORAGE_KEY)
  }

  return {
    ...computedUserData,
    loggedUser,
    files,
    token,
    isLogged,
    login,
    fetch,
    fetchAddress,
    fetchAvatar,
    setAvatar,
    setToken,
    disconnect,
    LogoFile
  }
})
