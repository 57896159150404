import { createRouter, createWebHistory } from 'vue-router'
import * as CustomersRoutes from '@/datas/customers/routes'
import * as ProjectsRoutes from '@/datas/projects/routes'
import * as CommunityRoutes from '@/datas/community/routes'
import * as SettingsRoutes from '@/datas/settings/routes'
import * as PublicRoutes from '@/datas/public/routes'
import * as DocumentsRoute from '@/datas/documents/routes'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    meta: { title: 'Mon tableau de bord' },
    component: () => import('@/views/dashboard/DashboardView.vue')
  },
  {
    path: PublicRoutes.ROUTE_LOGIN_BASE,
    meta: { title: 'Me connecter' },
    component: () => import('@/views/login/LoginView.vue'),
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            name: 'login',
            component: () => import('@/views/login/LoginFormView.vue')
          },
          {
            path: PublicRoutes.ROUTE_LOGIN_ERROR,
            meta: { title: 'Erreur' },
            component: () => import('@/views/login/LoginFormErrorView.vue')
          }
        ]
      },
      {
        path: PublicRoutes.ROUTE_FORGOT_PWD,
        component: () => import('@/views/login/LoginForgotPwdView.vue'),
        children: [
          {
            path: '',
            meta: { title: '\'ai oublié mon mot de passe' },
            component: () => import('@/views/login/LoginForgotPwdFormView.vue')
          },
          {
            path: PublicRoutes.ROUTE_LOGIN_ERROR,
            meta: { title: 'Erreur' },
            component: () => import('@/views/login/LoginForgotPwdErrorView.vue')
          },
          {
            path: PublicRoutes.ROUTE_FORGOT_PWD_CONFIRM,
            meta: { title: 'J\'ai oublié mon mot de passe' },
            component: () => import('@/views/login/LoginForgotPwdConfirmView.vue')
          },
          {
            path: PublicRoutes.ROUTE_FORGOT_PWD_CONFIRM_ERROR,
            meta: { title: 'Erreur' },
            component: () => import('@/views/login/LoginForgotPwdConfirmErrorView.vue')
          }
        ]
      },
      {
        path: PublicRoutes.ROUTE_RESET_PWD,
        meta: { title: 'Je définis un nouveau mot de passe' },
        component: () => import('@/views/login/LoginResetPwdView.vue')
      },
      {
        path: PublicRoutes.ROUTE_SECURE_ACCOUNT,
        meta: { title: 'Je sécurise mon compte' },
        component: () => import('@/views/login/LoginSecureAccountView.vue')
      },
      {
        path: PublicRoutes.ROUTE_ACCOUNT_SECURED,
        meta: { title: 'Mon compte est sécurisé !' },
        component: () => import('@/views/login/LoginAccountSecuredView.vue')
      }
    ]
  },
  {
    path: CustomersRoutes.ROUTE_BASE,
    meta: { title: 'Clients' },
    children: [
      {
        path: '',
        component: () => import('@/views/customer/CustomersView.vue')
      },
      {
        path: CustomersRoutes.ROUTE_ADD,
        meta: { title: 'Ajouter un client' },
        component: () => import('@/views/customer/CustomersAddView.vue')
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: () => import('@/views/customer/CustomerSingleView.vue')
          },
          {
            path: CustomersRoutes.ROUTE_EDIT,
            component: () => import('@/views/customer/CustomersEditView.vue'),
            children: [
              {
                path: '',
                meta: { title: 'Modifier un client - Présentation générale' },
                component: () => import('@/views/customer/CustomersEditPresentationView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_CONTACT,
                meta: { title: 'Modifier un client - Coordonnées principales' },
                component: () => import('@/views/customer/CustomersEditContactDetailsView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_PEOPLES,
                meta: { title: 'Modifier un client - Contacts' },
                component: () => import('@/views/customer/CustomersEditContactPeoplesView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_LEGALS,
                meta: { title: 'Modifier un client - Informations juridiques' },
                component: () => import('@/views/customer/CustomersEditLegalsInfosView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_DOCUMENTS,
                meta: { title: 'Modifier un client - Documents & contrats' },
                component: () => import('@/views/customer/CustomersEditDocumentsView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_BILLINGS,
                meta: { title: 'Modifier un client - Facturation & comptabilité' },
                component: () => import('@/views/customer/CustomersEditBillingsView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_PROJECTS,
                meta: { title: 'Modifier un client - Activités, projets et services' },
                component: () => import('@/views/customer/CustomersEditProjectsView.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: ProjectsRoutes.ROUTE_BASE,
    meta: { title: 'Projets' },
    children: [
      {
        path: '',
        component: () => import('@/views/projects/ProjectsView.vue'),
        beforeEnter: () => {
          localStorage.removeItem('NAZ_CUSTOMER')
        }
      },
      {
        path: ProjectsRoutes.ROUTE_ADD,
        meta: { title: 'Projets - Créer un projet' },
        component: () => import('@/views/projects/ProjectsAddView.vue')
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: () => import('@/views/projects/ProjectSingleView.vue')
          },
          {
            path: ProjectsRoutes.ROUTE_EDIT,
            component: () => import('@/views/projects/ProjectEditView.vue'),
            children: [
              {
                path: '',
                meta: { title: 'Projets - Gestion du projet' },
                component: () => import('@/views/projects/ProjectEditManageView.vue')
              },
              {
                path: ProjectsRoutes.ROUTE_DESC,
                meta: { title: 'Projets - Description du projet' },
                component: () => import('@/views/projects/ProjectEditDescriptionView.vue')
              },
              {
                path: ProjectsRoutes.ROUTE_FORMATTING,
                meta: { title: 'Projets - Mise en forme' },
                component: () => import('@/views/projects/ProjectEditFormattingView.vue')
              },
              {
                path: ProjectsRoutes.ROUTE_TECH,
                meta: { title: 'Projets - Paramètres techniques' },
                component: () => import('@/views/projects/ProjectEditTechnicalView.vue')
              },
              {
                path: ProjectsRoutes.ROUTE_STRUCTURE,
                meta: { title: 'Projets - Architecture du projet' },
                component: () => import('@/views/projects/ProjectEditStructureView.vue')
              }
            ]
          },
          {
            path: ProjectsRoutes.ROUTE_EDIT_PAGE + '/:pageid',
            meta: { title: 'Projets - Editer une page' },
            component: () => import('@/views/projects/ProjectEditPageView.vue')
          }
        ]
      }
    ]
  },
  {
    path: DocumentsRoute.ROUTE_BASE,
    meta: { title: 'Documents' },
    children: [
      {
        path: '',
        component: () => import('@/views/documents/DocumentsView.vue')
      },
      {
        path: ':id',
        meta: { title: 'Documents' },
        component: () => import('@/views/documents/DocumentSingleView.vue')
      },
      {
        path: DocumentsRoute.ROUTE_FOLDER + '/:id',
        children: [
          {
            path: '',
            meta: { title: 'Documents' },
            component: () => import('@/views/documents/DocumentFolderView.vue')
          },
          {
            path: DocumentsRoute.ROUTE_DOCUMENT + '/:docid',
            meta: { title: 'Documents' },
            component: () => import('@/views/documents/DocumentSingleView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/providers',
    children: [
      {
        path: '',
        meta: { title: 'Prestataires tiers' },
        component: () => import('@/views/providers/ProvidersView.vue')
      }
    ]
  },
  {
    path: CommunityRoutes.ROUTE_BASE,
    children: [
      {
        path: '',
        meta: { title: 'Communauté' },
        component: () => import('@/views/community/CommunityView.vue')
      },
      {
        path: CommunityRoutes.ROUTE_ADD_CONTACT,
        meta: { title: 'Communauté - Ajouter un contact' },
        component: () => import('@/views/community/CommunityAddContactView.vue')
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            meta: { title: 'Communauté' },
            component: () => import('@/views/community/CommunitySingleContactView.vue')
          },
          {
            path: CommunityRoutes.ROUTE_EDIT,
            component: () => import('@/views/community/CommunityEditContactView.vue'),
            children: [
              {
                path: '',
                meta: { title: 'Communauté - A propos du contact' },
                component: () => import('@/views/community/CommunityEditContactAboutView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_PRESENTATION,
                meta: { title: 'Communauté - Présentation générale' },
                component: () => import('@/views/community/CommunityEditContactPresentationView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_CONTACT,
                meta: { title: 'Communauté - Coordonnées principales' },
                component: () => import('@/views/community/CommunityEditContactInfosView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_ROLES,
                meta: { title: 'Communauté - Rôles & implications' },
                component: () => import('@/views/community/CommunityEditContactRolesView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_SERVICES,
                meta: { title: 'Communauté - Services & options' },
                component: () => import('@/views/community/CommunityEditContactServicesView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_SECURITY,
                meta: { title: 'Communauté - Sécurité' },
                component: () => import('@/views/community/CommunityEditContactSecurityView.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/communication',
    children: [
      {
        path: '',
        meta: { title: 'Communication' },
        component: () => import('@/views/communication/CommunicationView.vue')
      }
    ]
  },
  {
    path: '/products',
    children: [
      {
        path: '',
        component: () => import('@/views/products/ProductsView.vue')
      }
    ]
  },
  {
    path: '/comptability',
    children: [
      {
        path: '',
        meta: { title: 'Comptabilité' },
        component: () => import('@/views/accounting/AccountingView.vue')
      }
    ]
  },
  {
    path: '/stats',
    children: [
      {
        path: '',
        meta: { title: 'Statistiques' },
        component: () => import('@/views/stats/StatsView.vue')
      }
    ]
  },
  {
    path: SettingsRoutes.ROUTE_BASE,
    meta: { title: 'Paramètres' },
    children: [
      {
        path: '',
        component: () => import('@/views/settings/SettingsView.vue'),
        beforeEnter: (to) => {
          if (to.path === SettingsRoutes.ROUTE_BASE) {
            return { path: SettingsRoutes.ROUTE_BASE + '/' + SettingsRoutes.ROUTE_GENERAL }
          }
        },
        children: [
          {
            path: SettingsRoutes.ROUTE_GENERAL,
            meta: { title: 'Paramètres - Informations générales' },
            component: () => import('@/views/settings/SettingsGeneralView.vue')
          },
          {
            path: SettingsRoutes.ROUTE_MY_PROFILE,
            meta: { title: 'Paramètres - Ma fiche' },
            component: () => import('@/views/settings/SettingsMyProfileView.vue')
          },
          {
            path: SettingsRoutes.ROUTE_LEGALS_DATA,
            meta: { title: 'Paramètres - Données juridiques' },
            component: () => import('@/views/settings/SettingsLegalDataView.vue')
          },
          {
            path: SettingsRoutes.ROUTE_COLLABORATORS,
            meta: { title: 'Paramètres - Collaborateurs' },
            component: () => import('@/views/settings/SettingsCollaboratorsView.vue')
          }
        ]
      },
      {
        path: 'edit',
        component: () => import('@/views/settings/SettingsEditView.vue'),
        beforeEnter: (to) => {
          if (to.path === SettingsRoutes.ROUTE_BASE + '/edit') {
            return { path: SettingsRoutes.ROUTE_BASE + '/edit/' + SettingsRoutes.ROUTE_GENERAL }
          }
        },
        children: [
          {
            path: SettingsRoutes.ROUTE_GENERAL,
            component: () => import('@/views/settings/SettingsGeneralEditView.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/settings/edit/SettingsGeneralPresentationEditView.vue')
              },
              {
                path: SettingsRoutes.ROUTE_CONTACT_DETAILS_EDIT,
                component: () => import('@/views/settings/edit/SettingsGeneralContactDetailsEditView.vue')
              },
              {
                path: SettingsRoutes.ROUTE_CONTACT_PEOPLES_EDIT,
                component: () => import('@/views/settings/edit/SettingsGeneralContactPeoplesEditView.vue')
              },
              {
                path: SettingsRoutes.ROUTE_SERVICES_STATE_EDIT,
                component: () => import('@/views/settings/edit/SettingsGeneralServicesStateEditView.vue')
              }
            ]
          },
          {
            path: SettingsRoutes.ROUTE_LEGALS_DATA,
            component: () => import('@/views/settings/SettingsLegalDataEditView.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/settings/edit/SettingsLegalDataEditView.vue')
              },
              {
                path: SettingsRoutes.ROUTE_LEGALS_DATA_REFS_EDIT,
                component: () => import('@/views/settings/edit/SettingsLegalDataReferenceEditView.vue')
              },
              {
                path: SettingsRoutes.ROUTE_LEGALS_DATA_REPRESENTATION_EDIT,
                component: () => import('@/views/settings/edit/SettingsLegalDataRepresentationEditView.vue')
              }
            ]
          },
          {
            path: SettingsRoutes.ROUTE_MY_PROFILE,
            component: () => import('@/views/community/CommunityEditContactView.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/community/CommunityEditContactAboutView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_PRESENTATION,
                component: () => import('@/views/community/CommunityEditContactPresentationView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_CONTACT,
                component: () => import('@/views/community/CommunityEditContactInfosView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_ROLES,
                component: () => import('@/views/community/CommunityEditContactRolesView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_SERVICES,
                component: () => import('@/views/community/CommunityEditContactServicesView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_SECURITY,
                component: () => import('@/views/community/CommunityEditContactSecurityView.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/support',
    meta: { title: 'Support' },
    children: [
      {
        path: '',
        component: () => import('@/views/support/SupportView.vue')
      }
    ]
  },
  {
    path: '/help',
    meta: { title: 'Aides et informations' },
    children: [
      {
        path: '',
        component: () => import('@/views/help/HelpView.vue')
      }
    ]
  },
  {
    path: '/planning',
    meta: { title: 'Planning' },
    children: [
      {
        path: '',
        component: () => import('@/views/planning/PlanningView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'Beautiful System'
  if (to.meta && to.meta.title) {
    document.title += ' - ' + to.meta.title
  }
  next()
})

export default router
