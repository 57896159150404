export const ROUTE_LOGIN_BASE = '/login'
export const ROUTE_LOGIN_ERROR = 'error'
export const ROUTE_FORGOT_PWD = 'forgot-password'
export const ROUTE_FORGOT_PWD_CONFIRM = 'confirm'
export const ROUTE_FORGOT_PWD_CONFIRM_ERROR = 'confirm-error'
export const ROUTE_RESET_PWD = 'reset-password'
export const ROUTE_SECURE_ACCOUNT = 'secure-account'
export const ROUTE_ACCOUNT_SECURED = 'account-secured'

export default {
  ROUTE_LOGIN_BASE,
  ROUTE_LOGIN_ERROR,
  ROUTE_FORGOT_PWD,
  ROUTE_FORGOT_PWD_CONFIRM,
  ROUTE_FORGOT_PWD_CONFIRM_ERROR,
  ROUTE_RESET_PWD,
  ROUTE_SECURE_ACCOUNT,
  ROUTE_ACCOUNT_SECURED
}
